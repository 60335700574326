import PropTypes from "prop-types";
import React, { useContext } from "react";
import { scenarioTypes } from "../../../../commons/enum";
import LoadingBackdrop from "../../../../components/loading-backdrop";
import { FinanceContext } from "../../../../context/finance-context";
import { useScenarioProductListByScenarioId } from "../../../../hooks/scenario.hooks";
import { formatNumberWithDollar } from "../../../../utils/numberFormater";
import BorrowCapacityList from "./borrow-capacity-list";

const BorrowCapacityListComponent = ({
  isLoading,
  isError,
  scenarioObject = {},
  productList = [],
}) => {
  const { selectedScenarioId } = useContext(FinanceContext);
  const {
    isLoading: gettingScenarioProductList,
  } = useScenarioProductListByScenarioId(selectedScenarioId);

  const { lvrSetting = {} } = scenarioObject;
  const { lvr = 0, estimateProperty = 0, cashTowardProperty = 0 } = lvrSetting;
  const lenderLoanAmount = estimateProperty - cashTowardProperty;

  return (
    <>
      <div className="borrow-capacity-product-list position-relative">
        {productList.length > 0 ? (
          <>
            <div className="ex-header-tabs">
              <div className="bc-list-title">
                {scenarioObject.scenarioType !==
                scenarioTypes.REFINANCE_HOME_LOAN ? (
                  <>
                    Lender Options for cash towards{" "}
                    <span>{formatNumberWithDollar(cashTowardProperty)}</span>
                  </>
                ) : (
                  <>
                    {`Lender Options for loan amount of `}
                    <span>
                      {lenderLoanAmount
                        ? formatNumberWithDollar(lenderLoanAmount)
                        : formatNumberWithDollar(0)}{" "}
                      LVR {lvr}%
                    </span>
                  </>
                )}
              </div>
            </div>
            <BorrowCapacityList
              productList={productList}
              scenarioObject={scenarioObject}
            />
          </>
        ) : (
          !gettingScenarioProductList && (
            <div className="no-lender-option">
              {isError
                ? "Failed to get Lender Option. Please wait a few minutes before you try again"
                : "No Lender Option available for this Scenario."}
            </div>
          )
        )}
        <LoadingBackdrop isOpen={isLoading || gettingScenarioProductList} />
      </div>
    </>
  );
};

BorrowCapacityListComponent.propTypes = {
  productList: PropTypes.array,
};

export default BorrowCapacityListComponent;

import { makeStyles } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { loanTypes } from "../../../../commons/enum";
import LoadingSpinner from "../../../../components/loading-spinner";
import { FinanceContext } from "../../../../context/finance-context";
import { useIsTablet } from "../../../../hooks/common.hooks";
import { useServiceProviders } from "../../../../hooks/scenario.hooks";
import { convertToFloat } from "../../../../utils/convert-number";
import { formatNumberWithDollar } from "../../../../utils/numberFormater";
import { titleCase } from "../../../../utils/text-format";
import BorrowCapacityListItem from "./borrow-capacity-list-item";

const useStyles = makeStyles({
  paddingTop: {
    padding: "1.5rem 0px 0px 16px ",
  },
});
const numberItemVisibleFirstLoad = 5;
const numberNumberAddAfterLoadMore = 5;

const BorrowCapacityList = ({ productList = [] }) => {
  const classes = useStyles();
  const isTablet = useIsTablet();
  const { selectedScenarioDetail = {} } = useContext(FinanceContext);
  const { scenarioObject = {} } = selectedScenarioDetail;
  const { loanType = "" } = scenarioObject;
  const { data: lenderList = {} } = useServiceProviders();

  const [onLoad, setonLoad] = useState(false);
  const [lenderAvailableList, setLenderAvailableList] = useState([]);
  const [debtLimit, setDebtLimit] = useState(numberItemVisibleFirstLoad);

  const handleLoadMore = () => {
    setDebtLimit(debtLimit + numberNumberAddAfterLoadMore);
    setonLoad(true);
  };
  useEffect(() => {
    setDebtLimit(numberItemVisibleFirstLoad);
  }, []);

  useEffect(() => {
    if (productList.length > 0) {
      setLenderAvailableList(productList.slice(0, debtLimit));
    } else {
      setLenderAvailableList([]);
    }
    setonLoad(false);
  }, [debtLimit, productList]);
  return (
    <>
      <div className="bc-list-sub-title">
        <div className="bc-list-sub-title-item">Lender</div>
        <div className="bc-list-sub-title-item">Interest Rate</div>
        <div className="bc-list-sub-title-item">Comparison Rate</div>
        <div className="bc-list-sub-title-item">Monthly Repayments</div>
        <div className="bc-list-sub-title-item">Total Cost</div>
        <div className="bc-list-sub-title-item">Real Buying Power</div>
      </div>
      <Timeline className={classes.paddingTop}>
        {lenderAvailableList.length > 0 &&
          lenderAvailableList.map((listData, index) => {
            const {
              lenderId = "",
              baseRate = 0,
              rates = [],
              maxBuyingPower = 0,
              comparisonRate = 0,
              monthlyRepaymentList = [],
              annualFee = 0,
              maxTerm = 0,
              setUpFee = 0,
            } = listData;
            const [ratesItem = {}] = rates;
            const { rate = 0 } = ratesItem;
            const lenderDetail = lenderList[lenderId] || {};
            const { longName = "" } = lenderDetail;
            const monthlyRepayment = monthlyRepaymentList.reduce(
              (result, item, repaymentIndex) => {
                if (repaymentIndex > 0) {
                  return `${result} - ${formatNumberWithDollar(
                    Math.round(item)
                  )}`;
                }
                return `${result} ${formatNumberWithDollar(Math.round(item))}`;
              },
              ""
            );
            const totalCost = annualFee * maxTerm + setUpFee;
            return (
              <TimelineItem key={`timelineItem${lenderId + index}`}>
                {!isTablet && (
                  <TimelineSeparator>
                    <TimelineDot />
                    {index !== lenderAvailableList.length - 1 && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>
                )}

                <TimelineContent>
                  <BorrowCapacityListItem
                    lender={titleCase(longName)}
                    interestRate={
                      loanType === loanTypes.STANDARD_VARIABLE
                        ? convertToFloat(baseRate, 2)
                        : convertToFloat(rate, 2)
                    }
                    comparisonRate={comparisonRate}
                    monthlyRepayment={monthlyRepayment}
                    totalCost={totalCost}
                    maxBuying={maxBuyingPower}
                  />
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
      <div className="load-more-button-container">
        <button
          className="btn load-more-button"
          type="button"
          onClick={handleLoadMore}
          // eslint-disable-next-line no-restricted-globals
          disabled={onLoad}
        >
          {onLoad ? <LoadingSpinner /> : `Load More`}
        </button>
      </div>
    </>
  );
};

BorrowCapacityList.propTypes = {
  productList: PropTypes.array,
};

export default BorrowCapacityList;

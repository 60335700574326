import PropTypes from "prop-types";
import React from "react";
import {
  formatNumberWithDollar,
  formatNumberWithPercent,
} from "../../../../utils/numberFormater";

const BorrowCapacityListItem = ({
  lender = "",
  interestRate = 0,
  comparisonRate = 0,
  monthlyRepayment = "",
  totalCost = 0,
  maxBuying = 0,
}) => {
  return (
    <div className="bc-list-item">
      <div className="bc-list-sub-title-item">{lender}</div>
      <div className="bc-list-sub-title-item">
        {interestRate ? formatNumberWithPercent(interestRate) : "n/a"}
      </div>
      <div className="bc-list-sub-title-item">
        {comparisonRate ? formatNumberWithPercent(comparisonRate) : "n/a"}
      </div>
      <div className="bc-list-sub-title-item">
        {monthlyRepayment || formatNumberWithDollar(0)}
      </div>
      <div className="bc-list-sub-title-item">
        {totalCost
          ? formatNumberWithDollar(totalCost)
          : formatNumberWithDollar(0)}
      </div>
      <div className="bc-list-sub-title-item">
        {maxBuying
          ? formatNumberWithDollar(maxBuying)
          : formatNumberWithDollar(0)}
      </div>
    </div>
  );
};

BorrowCapacityListItem.propTypes = {
  lender: PropTypes.string,
  interestRate: PropTypes.number,
  comparisonRate: PropTypes.number,
  monthlyRepayment: PropTypes.string,
  totalCost: PropTypes.number,
  maxBuying: PropTypes.number,
};

export default BorrowCapacityListItem;
